<template>
	<div class="mb-4 w-100">
		<div v-show="!partner" class="w-1/1 relative" @click.stop.prevent="$emit('click')">
			<b-form-input
				class="w-2/3 inline-block z-30"
				v-model.trim="partnerKeyword"
				name="selectPartner"
				placeholder="파트너사명 입력"
				@keyup.enter="searchPartner"
			/>
			<b-button class="ml-2 px-8 text-white" @click="searchPartner" variant="primary">검색</b-button>
			<vSelect
				id="partnerSelect"
				class="w-2/3 absolute top-0 left-0 border-none z-20"
				v-model="partner"
				@input="changePartner"
				:options="partnerOpts"
				:pr="ths"
				:searchable="false"
				cName="partnerSelect"
			/>
		</div>
		<div v-show="partner">
			<b-badge class="h-6.5 lh-5 cursor-pointer" @click="removePartner" pill variant="success"
				>{{ partner ? partner.label : '' }}
				<b-icon v-show="isRemovable" class="mx-0.5" icon="x" scale="2" />
			</b-badge>
			<p v-if="showPic" class="mt-2">계약 담당자 : {{ partner.contractPicName }}</p>
		</div>
	</div>
</template>

<script>
export default {
	props: { cName: { default: 'selectPartner' }, isRemovable: { default: true }, showPic: { default: false } },

	data() {
		return {
			partner: '',
			partnerKeyword: '',
			partnerOpts: [],
			lastOpt: {},
		}
	},
	methods: {
		getPartner() {
			return this.partner
		},
		setPartner(partner) {
			this.partner = partner
		},
		searchPartner() {
			if (this.partnerKeyword.length > 1) {
				if (this.lastOpt.comName != this.partnerKeyword) {
					this.lastOpt = {
						comName: this.partnerKeyword,
					}
					postApi('/partnerSelect/partnerSearchList', this.lastOpt).then(res => {
						this.partnerOpts = res.map(v => {
							return { value: v.storeSeq, label: v.comName, contractPicName: v.contractPicName }
						})
						this.isSetItem = true
						this.partnerSelect.open = true
					})
				} else this.partnerSelect.open = true
			} else alert.w('파트너사명을 두 글자 이상 입력해주세요')
		},
		changePartner(item) {
			//{ label: name, value: seq }
			this.$emit('change', item)
		},
		removePartner() {
			if (this.isRemovable) {
				this.partner = ''
				this.$emit('change', '')
			}
		},
	},
}
</script>
