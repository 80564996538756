<template>
	<div>
		<p>광고 운영에 필요한 데이터와 업체에 제공되는 광고보고서를 다운로드 받으세요.</p>
		<searchCard :reset="reset">
			<h6>기간 선택</h6>
			<div class="flex">
				<b-form-radio
					v-for="(v, i) in ['오늘', '이번 달', '지난 달', '']"
					:key="v + i"
					class="mr-5"
					v-model="input.period"
					name="period"
					:value="v"
				>
					{{ v }}
				</b-form-radio>

				<div class="-mt-1.5 flex">
					<datePicker class="-ml-3" model="input.startDate" />
					<span class="p-2 lh-6">~</span>
					<datePicker model="input.endDate" />
				</div>
			</div>
			<b-form-checkbox class="ml-0 mt-4" v-model="input.isValidDealOnly" color="primary"
				>유효딜만</b-form-checkbox
			>
			<p class="mt-1 mb-4">ⓘ ‘광고 운영 데이터(딜별)’에만 적용됩니다.</p>
			<h6>추출 대상 선택</h6>
			<div class="flex mb-4">
				<b-form-radio
					v-for="(v, i) in ['전체', '파트너사 선택']"
					:key="v + i"
					class="mr-5"
					v-model="input.partner"
					name="partner"
					:value="v"
				>
					{{ v }}
				</b-form-radio>
				<selectPartner class="-mt-1.5" @change="changePartner" @click="input.partner = '파트너사 선택'" />
			</div>

			<h6>패키지 선택</h6>
			<div class="flex">
				<b-form-radio
					v-for="(v, i) in ['전체', '테스트 패키지', '프리미엄 패키지', '베이직 패키지', '기본 패키지']"
					:key="v + i"
					class="mr-5"
					v-model="input.package"
					name="package"
					:value="v"
				>
					{{ v }}
				</b-form-radio>
			</div>
			<p class="mt-1 mb-4">ⓘ ‘광고 운영 데이터(딜별)’, ‘파트너사 광고보고서’에만 적용됩니다.</p>
		</searchCard>
		<b-card class="p-4 z-10 mt-6">
			<p class="mb-8 mt-2">
				판매 데이터 : {{ lastUpdateTime.salesData }} 기준 / 페이스북 광고 데이터 :
				{{ lastUpdateTime.facebookData }} 기준
			</p>
			<b-button
				v-for="v in [
					{ name: '1. 광고 운영 데이터 (딜별)', data: 1 },
					{ name: '2.상품별 수익성 데이터', data: 2 },
					{ name: '3. 파트너사별 수익성 데이터', data: 3 },
				]"
				class="mr-6 mb-6 align-top"
				@click="downloadReport(v.data)"
				variant="info"
				v-text="v.name"
			/>
			<br />

			<b-button
				v-for="v in [
					{ name: '4. 파트너사 광고보고서(1) - 1일', data: 4 },
					{ name: '5. 파트너사 광고보고서(2) - 7일', data: 5 },
					{ name: '6. 파트너사 광고보고서(2) - 1일', data: 6 },
				]"
				class="mr-6 mb-6 align-top"
				@click="downloadReport(v.data)"
				variant="warning"
				v-text="v.name"
			/>
			<p class="-mt-4">ⓘ 추출 대상 선택에서 파트너사를 선택해야 다운로드 가능합니다.</p>

			<b-button class="mt-4" @click="downloadReport(7)" variant="gray" v-text="'기본 패키지 딜 등록비'" />
		</b-card>
	</div>
</template>

<script>
import selectPartner from 'comp/local/selectPartner'

const today = new Date(),
	y = today.getFullYear(),
	m = today.getMonth(),
	d = today.getDate(),
	defInput = {
		period: '오늘',
		startDate: new Date(y, m, d - 30),
		endDate: today,

		isValidDealOnly: false,

		partner: '전체',
		package: '전체',
		searchKeyword: '',

		selectedItem: '',

		//observer: null,
	}

export default {
	components: { selectPartner },
	data() {
		return {
			lastUpdateTime: {},
			input: { ...defInput },
			today: today.get_time('d'),
			currentMonthFrom: new Date(y, m).get_time('d'),
			prevMonthFrom: new Date(y, m - 1).get_time('d'),
			prevMonthTo: new Date(y, m, 0).get_time('d'),

			isSetItem: false,
			lastOpt: { comName: '' },
			res: {},
		}
	},
	methods: {
		reset() {
			this.input = { ...defInput }
		},
		changePartner(item) {
			this.input.selectedItem = item
		},
		downloadReport(kind) {
			let url,
				data = {}

			const input = this.input,
				ex = new excel()

			let isConstDate = true
			switch (input.period) {
				case '오늘':
					data.startDate = this.today
					data.endDate = this.today
					break
				case '이번 달':
					data.startDate = this.currentMonthFrom
					data.endDate = this.today
					break
				case '지난 달':
					data.startDate = this.prevMonthFrom
					data.endDate = this.prevMonthTo
					break
				default:
					data.startDate = input.startDate.get_time('d')
					data.endDate = input.endDate.get_time('d')
					isConstDate = false
			}
			if (isConstDate) ex.key('기간 선택', input.period)
			else ex.date(input.startDate, input.endDate)

			switch (kind) {
				case 1:
					//1. 광고 운영 데이터 (딜별)
					url = '/advertisementSelect/downloadDealRevenue'
					data.isValidDealOnly = input.isValidDealOnly
					if (data.isValidDealOnly) ex.push('유효딜만 보기')
					break
				case 2:
					//2.상품별 수익성 데이터
					url = '/advertisementSelect/downloadProductGroupRevenue'
					break
				case 3:
					//3. 파트너사별 수익성 데이터
					url = '/advertisementSelect/downloadStoreRevenue'
					break
				case 4:
				case 5:
				case 6:
					//4. 파트너사 광고보고서
					if (!this.isAvailableMidReport) {
						alert.w('추출 대상 선택에서 파트너사를 선택해야 다운로드 가능합니다.')
						return
					}
					let inputPackage = ''
					switch (input.package) {
						case '테스트 패키지':
							inputPackage = 'test'
							break
						case '프리미엄 패키지':
							inputPackage = 'premium'
							break
						case '베이직 패키지':
							inputPackage = 'basic'
							break
						case '기본 패키지':
							inputPackage = 'default'
							break
						default:
							//전체
							inputPackage = 'all'
					}
					data.contractPackage = inputPackage
					ex.key('패키지', input.package)
					if (kind == 4) url = '/advertisementSelect/downloadStoreAdvertisementReport'
					else {
						url = 'api/backoffice/advertisementSelect/downloadStoreAdvertisementReport'
						data.contributionDays = kind == 5 ? 'seven' : 'one'
					}
					break
				case 7:
					url = 'api/backoffice/advertisementSelect/downloadDealRegistrationFeeList'
					break
				default:
					alert.w('잘못된 접근입니다')
					return
			}

			let condition = `추출 대상 선택: ${input.partner}`
			if (input.partner == '파트너사 선택') {
				if (!input.selectedItem) {
					alert.w('파트너사를 선택해주세요.')
					return
				}
				data.storeSeq = input.selectedItem.value
				condition += ` - ${input.selectedItem.label} (${data.storeSeq})`
			}

			postApi(url, ex.push(condition).getObj(this, data)).then(res => {
				alert.excel()
			})
		},
	},
	created() {
		postApi('/advertisementSelect/updatedTime').then(time => {
			this.lastUpdateTime = {
				salesData: time.salesData.replace('T', ' '),
				facebookData: time.facebookData.replace('T', ' '),
			}
		})
	},

	computed: {
		listItem() {
			return this.isSetItem ? this.res : []
		},
		isAvailableMidReport() {
			return this.input.partner == '파트너사 선택' && this.input.selectedItem
		},
	},
}
</script>

<style>
#partnerSelect .vs__dropdown-toggle {
	border: none !important;
}
</style>
